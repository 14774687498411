import React from 'react';
import Social from './Social';
import googlesiteseguro from "../../images/icons/SafeBrowsing_Logo_Vert_960.png"


export default ({ children }) => (
  <ul className="mx-auto px-4 lg:px-20 py-4 w-full flex bg-white justify-around">
    <li>
      <a
        alt="Google Security"
        href="https://www.google.com/transparencyreport/safebrowsing/diagnostic/?hl=pt-BR#url=sostrass.com.br"
        rel="noopener noreferrer nofollow"
        target="_blank"
        title="Google Security"
      >
        <img
          alt="Loja Confiável by Google"
          className="m-2 align-bottom h-20 object-scale-down"
          src={ googlesiteseguro }
        />
      </a>
    </li>
    <li>
      <a
        href="https://www.sitelock.com/verify.php?site=sostrass.com.br"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        <img
          alt="SiteLock"
          className="img-responsive"
          src="//shield.sitelock.com/shield/sostrass.com.br"
          title="SiteLock"
        />
      </a>
    </li>
    <li>
      <Social />
    </li>
  </ul>
);
