import React, { Component } from 'react';
import { Link } from 'gatsby';

export default ({ children }) => (
  <div className="carousel-outer bg-white font-serif">
    <div>
      <ul className="hidden md:flex justify-between px-2 lg:px-20 py-3 text-sm">
        <li>
        <Link to='/categoria/promocao'>
          <p className="text-gray-800 text-xl font-bold">
            OFERTAS IMPERDÍVEIS!
          </p>
          </Link>
        </li>
        <li>
          <Link to='/categoria/promocao'>
          <p>
            até <span className="text-gray-800 text-xl font-bold">20%</span> OFF
          </p>
          </Link>
        </li>
        <li>
          <Link to='/categoria/promocao'>
          <p>
            até <span className="text-gray-800 text-xl font-bold">30%</span> OFF
          </p>
          </Link>
        </li>
        <li>
          <Link to='/categoria/promocao'>
          <p>
            até <span className="text-gray-800 text-xl font-bold">40%</span> OFF
          </p>
          </Link>
        </li>
        <li>
          <Link to='/categoria/promocao'>
          <p>
            até <span className="text-gray-800 text-xl font-bold">50%</span> OFF
          </p>
          </Link>
        </li>
      </ul>
    </div>
  </div>
);
