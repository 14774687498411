import React, { Component } from 'react';

import visa from '../../images/paymentflags/visa.png';
import mcard from '../../images/paymentflags/mcard.png';
import elo from '../../images/paymentflags/elo.png';
import americanexpress from '../../images/paymentflags/american-express.png';
import dinnersclub from '../../images/paymentflags/dinners-club.png';
import hipercard from '../../images/paymentflags/hipercard.png';
import alelo from '../../images/paymentflags/alelo.png';
import ticket from '../../images/paymentflags/ticket.png';
import vrbeneficios from '../../images/paymentflags/vr-beneficios.png';
import valecard from '../../images/paymentflags/valecard.png';
import sodexo from '../../images/paymentflags/sodexo.png';
import verocheque from '../../images/paymentflags/verocheque.png';
import jcb from '../../images/paymentflags/jcb.png';
import aura from '../../images/paymentflags/aura.png';
import goodcard from '../../images/paymentflags/goodcard.png';
import discover from '../../images/paymentflags/discover.png';
import credz from '../../images/paymentflags/credz.png';
import banescard from '../../images/paymentflags/banescard.png';
import greencard from '../../images/paymentflags/greencard.png';
import mais from '../../images/paymentflags/mais.png';
import sorocred from '../../images/paymentflags/sorocred.png';
import policard from '../../images/paymentflags/policard.png';
import cabal from '../../images/paymentflags/cabal.png';
import redecompras from '../../images/paymentflags/redecompras.png';
import maxxvan from '../../images/paymentflags/maxxvan.png';
import calcard from '../../images/paymentflags/calcard.png';
import hiper from '../../images/paymentflags/hiper.png';
import credsystem from '../../images/paymentflags/credsystem.png';
import up from '../../images/paymentflags/up.png';
import avancard from '../../images/paymentflags/avancard.png';
import banpara from '../../images/paymentflags/banpara.png';
import bigcard from '../../images/paymentflags/bigcard.png';
import biq from '../../images/paymentflags/biq.png';
import bnbclube from '../../images/paymentflags/bnbclube.png';
import brasilcard from '../../images/paymentflags/brasilcard.png';
import convcard from '../../images/paymentflags/convcard.png';
import convenios from '../../images/paymentflags/convenios.png';
import cooper from '../../images/paymentflags/cooper.png';
import credialimentacao from '../../images/paymentflags/credialimentacao.png';
import credifarma from '../../images/paymentflags/credifarma.png';
import credpar from '../../images/paymentflags/credpar.png';
import diamante from '../../images/paymentflags/diamante.png';
import credsis from '../../images/paymentflags/credsis.png';
import facecard from '../../images/paymentflags/facecard.png';
import fortbrasil from '../../images/paymentflags/fortbrasil.png';
import fortcard from '../../images/paymentflags/fortcard.png';
import goiascard from '../../images/paymentflags/goiascard.png';
import goldplatinum from '../../images/paymentflags/goldplatinum.png';
import cardideal from '../../images/paymentflags/cardideal.png';
import lecard from '../../images/paymentflags/lecard.png';
import libercard from '../../images/paymentflags/libercard.png';
import fleet from '../../images/paymentflags/fleet.png';
import maxxcard from '../../images/paymentflags/maxxcard.png';
import nutricard from '../../images/paymentflags/nutricard.png';
import onecard from '../../images/paymentflags/onecard.png';
import personalcard from '../../images/paymentflags/personalcard.png';
import planvale from '../../images/paymentflags/planvale.png';
import pontocerto from '../../images/paymentflags/pontocerto.png';
import romcard from '../../images/paymentflags/romcard.png';
import senff from '../../images/paymentflags/senff.png';
import sincard from '../../images/paymentflags/sincard.png';
import soma from '../../images/paymentflags/soma.png';
import sindplus from '../../images/paymentflags/sindplus.png';
import lopes from '../../images/paymentflags/lopes.png';
import systemfarma from '../../images/paymentflags/systemfarma.png';
import triocard from '../../images/paymentflags/triocard.png';
import usecred from '../../images/paymentflags/usecred.png';
import valemais from '../../images/paymentflags/valemais.png';
import valeshop from '../../images/paymentflags/valeshop.png';
import vegas from '../../images/paymentflags/vegas.png';
import vscard from '../../images/paymentflags/vscard.png';

export default ({ children }) => (
  <div>
    <ul className="p-2 lg:px-4 lg:px-20 flex bg-gray-300 justify-between">
      <li className="hidden lg:block">
        <p className="my-2 align-bottom">Pagamento:</p>
      </li>
      <li>
        <img
          alt="visa"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={visa}
        />
      </li>
      <li>
        <img
          alt="mcard"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={mcard}
        />
      </li>
      <li>
        <img
          alt="elo"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={elo}
        />
      </li>
      <li>
        <img
          alt="americanexpress"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={americanexpress}
        />
      </li>
      <li>
        <img
          alt="dinnersclub"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={dinnersclub}
        />
      </li>
      <li>
        <img
          alt="hipercard"
          className="lg:m-2 h-4 lg:h-6 object-scale-down"
          src={hipercard}
        />
      </li>
    </ul>
  </div>
);
