import React, { Component } from "react";
import { NotificationManager } from 'react-notifications';

const defaultState = {
  productsOnCart: {
  },
  listAllProducts: {
  },
  totalCartProducts: 0,
  cartInfo: {
    dateCreated: null,
    uuid: null,
  },
}
const StoreContext = React.createContext(defaultState);
export default StoreContext;

class StoreContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState
  }

  componentDidMount() {
    const productsOnCart = JSON.parse(localStorage.getItem(`productsOnCart`));
    const cartInfo = JSON.parse(localStorage.getItem(`cartInfo`));
    if (productsOnCart) {
      const totalCartProducts = this.getTotalProductsOnCart(productsOnCart);
      this.setState({ productsOnCart, totalCartProducts, cartInfo });
    }
    // this.getProducts();
  }

  addProductToCart = (id, values) => {
    const { productsOnCart } = this.state;
    if (!productsOnCart[id]) {
      productsOnCart[id] = values;
    } else {
      productsOnCart[id].qtd += values.qtd;
    }
    const totalCartProducts = this.getTotalProductsOnCart(productsOnCart);
    this.setState({productsOnCart, totalCartProducts})
    localStorage.setItem(`productsOnCart`, JSON.stringify(productsOnCart));
    NotificationManager.success(`${values.name} adicionado ao carrinho.`, `Produto adicionado!`, 3000);
  }

  deleteProductOnCart = (id) => {
    const { productsOnCart } = this.state;
    delete productsOnCart[id];
    const totalCartProducts = this.getTotalProductsOnCart(productsOnCart);
    this.setState({ productsOnCart, totalCartProducts });
    localStorage.setItem(`productsOnCart`, JSON.stringify(productsOnCart));
    NotificationManager.success(`produto removido do carrinho.`, `Produto removido!`, 3000);
  }

  changeQtdCartProduct = (id, newQtd) => {
    const { productsOnCart } = this.state;
    productsOnCart[id].qtd = newQtd;;
    const totalCartProducts = this.getTotalProductsOnCart(productsOnCart);
    this.setState({ productsOnCart, totalCartProducts });
    localStorage.setItem(`productsOnCart`, JSON.stringify(productsOnCart)) 
  }

  clearCartProducts = () => {
    localStorage.setItem(`productsOnCart`, JSON.stringify({}));
  }

  getTotalProductsOnCart = (newList) => {
    let total = 0;
    Object.keys(newList).forEach((id) => {
      total += newList[id].qtd;
    });
    return total;
  }

  saveCartInfo = (cartInfo) => {
    localStorage.setItem(`cartInfo`, JSON.stringify(cartInfo));
    this.setState({ cartInfo });
  }

  render() {
    const { children } = this.props
    return (
      <StoreContext.Provider value={{
        state: this.state,
        addProductToCart: this.addProductToCart,
        deleteProductOnCart: this.deleteProductOnCart,
        changeQtdCartProduct: this.changeQtdCartProduct,
        clearCartProducts: this.clearCartProducts,
        saveCartInfo: this.saveCartInfo,
      }}>
        {children}
      </StoreContext.Provider>
    )
  }
}

export { StoreContextProvider }

