import React from 'react';

export default ({ children }) => (
  <>
    <div className="mx-auto mb-12 px-4 lg:px-20 py-4 md:py-8 text-sm bg-white text-gray-600">
      <p>
        Empresa <strong>importadora</strong> com foco no seguimento de
        <strong> insumos para bijuterias</strong>, calçados, confecções,
        artesanato, decoração e acessórios em geral. Localizada no maior centro
        de produção de jóias folheadas da América Latina, tem como objetivo
        oferecer comodidade, praticidade e segurança aos seus clientes compras
        através do e-commerce.
        <br />
        Preços e condições exclusivos para o site www.sostrass.com.br, podendo
        sofrer alterações sem prévia notificação. CNPJ: 42.614.714/0001-30 -
        Telefone: (19) 99963-0819 - contato@sostrass.com.br.
      </p>
    </div>
  </>
);
