import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import(`prismjs/themes/prism.css`);
import 'react-notifications/lib/notifications.css';
import './src/css/style.css';

// contexts
import React from 'react';
import { StoreContextProvider } from './src/context/StoreContext';
import { NotificationContainer } from 'react-notifications';

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>
    {element}
    <NotificationContainer />
  </StoreContextProvider>
)
