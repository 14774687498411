import React from 'react';
import { Link } from 'gatsby';

// import Search from './Search';

import AppBar from './appBar';
import MenuInfo from './appBar/menu.json';

import logo from '../images/sostrass_logo_roxo.svg';

import { MdAccountCircle, MdHeadsetMic } from 'react-icons/md';
import { AiOutlineShopping } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';

function Header({ totalCartProducts }) {
  return (
    <>
      <header className="lg:sticky z-50 top-0">
        <AppBar
          menu={MenuInfo.menu}
          totalCartProducts={totalCartProducts}
          imageUrl={logo}
          linkLogo="/"
        />
      </header>
    </>
  );
}

export default Header;
