import React, { Component } from 'react';

import { FaRegCreditCard } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaShippingFast } from 'react-icons/fa';
import { FaDollarSign } from 'react-icons/fa';

export default ({ children }) => (
  <div className="z-50 hidden px-2 py-1 font-serif text-sm text-white bg-gray-900 md:flex md:justify-between lg:px-20">
    <div>
      <FaRegCreditCard className="inline mr-1" />
      Parcelamento em até 6x
    </div>
    {/*
    <div>
      <FaStar className="inline mr-1" />
      ebit
    </div>
    */}
    <div>
      <FaShippingFast className="inline mr-1" />
      Frete Grátis acima de R$ 5.000,00 (Estado de SP)
    </div>
    <div>
      <FaDollarSign className="inline mr-1" />
      10% de desconto no pix
    </div>
  </div>
);
