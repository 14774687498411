import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { FaRegUser } from 'react-icons/fa';
import {
  AiOutlineSearch,
  AiOutlineShopping,
  AiOutlineMenu
} from 'react-icons/ai';

import { MdAccountCircle, MdHeadsetMic } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';

import './AppBar.css';

const NavBar = ({
  menu,
  onProfile,
  onSearch,
  onCart,
  imageUrl,
  linkLogo,
  totalCartProducts
}) => {
  const [isExpanded, toggleExpansion] = useState(false);

  const openMenu = () => {
    toggleExpansion(!isExpanded);
  };

  return (
    <nav className="text-gray-900 bg-white shadow-md">
      <div className="container flex justify-between mx-auto">
        <p className="pt-8 pl-6 cursor-pointer md:hidden" onClick={openMenu}>
          <AiOutlineMenu />
        </p>

        <div className="relative block px-2 py-6 text-xl font-bold lg:py-5 lg:pr-2 text-primary">
          <a href={linkLogo}>
            <img
              alt="logo"
              sizes={100}
              src={imageUrl}
              style={{ height: 30, minWidth: 60 }}
            />
          </a>
        </div>

        <ul className="hidden md:flex md:flex-row">
          {menu?.map(({ title, submenu, route, banner, banner_url }, index) => (
            <li
              className="border-b-2 border-transparent border-double cursor-pointer select-none hoverable hover:border-current hover:text-black"
              key={index}
            >
              <Link
                className="relative block px-4 pt-6 text-black lg:p-3 lg:pt-8 md:text-xs 2xl:text-sm hover:text-black"
                to={route}
              >
                {title}
              </Link>
              <div className="p-6 mb-16 bg-white shadow-xl mega-menu sm:mb-0">
                <div className="container flex flex-wrap justify-center w-full mx-auto bg-white">
                  <div className="w-full mb-8 text-black"></div>

                  <ul>
                    <li>
                      <ul>{title}</ul>
                      <div className="flex flex-wrap overflow-hidden xl:-mx-px">
                        <div className="w-full overflow-hidden xl:my-px xl:px-px xl:w-2/3">
                          <div className="flex flex-row flex-wrap overflow-hidden xl:-mx-4">
                            {submenu.map(({ title, route }, index) => (
                              <div
                                className="py-2 overflow-hidden rounded xl:my-2 xl:px-2 xl:w-1/2 bg-white-100 hover:bg-gray-100 category-size"
                                key={index}
                              >
                                <Link
                                  className="flex flex-wrap text-sm text-gray-700"
                                  to={route}
                                >
                                  <svg
                                    className="w-2 mx-2 h2 text-primary"
                                    fill="currentColor"
                                    viewBox="0 0 8 8"
                                  >
                                    <circle cx="4" cy="4" r="3" />
                                  </svg>
                                  {title}
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                        {banner && (
                          <div className="w-full overflow-hidden xl:my-px xl:px-px xl:w-1/3">
                            <a href={banner_url} rel="noreferrer">
                              <img
                                alt="banner"
                                src={banner}
                                style={{ width: 220 }}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {/*        <div className="flex justify-between py-4">
          <div
            onClick={onProfile}
            className="relative block pt-5 pl-2 text-xl font-bold cursor-pointer lg:p-3 text-black-600"
          >
            <FaRegUser />
          </div>
          <div
            onClick={onSearch}
            className="relative block pt-5 pl-2 text-xl font-bold cursor-pointer lg:p-3 text-black-600 md:flex md:flex-row"
          >
            <AiOutlineSearch />
          </div>
          <div
            onClick={onCart}
            className="relative block px-2 pt-5 text-xl font-bold cursor-pointer lg:p-3 text-black-600"
          >
            <AiOutlineShopping />
          </div>
        </div>*/}

        <div className="flex pr-1 mt-6 text-primary">
          <a
            className="block pr-2 no-underline md:inline-block"
            href="https://api.whatsapp.com/send?phone=5519999630819"
            key="whatsapp"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <MdHeadsetMic className="w-6 h-8" />
          </a>
          <Link
            className="block pr-2 no-underline md:inline-block"
            key="account"
            to="https://checkout.sostrass.com.br/my-account/"
          >
            <MdAccountCircle className="w-6 h-8" />
          </Link>
          <Link
            className="block pr-2 no-underline md:inline-block"
            key="search"
            to="/search"
          >
            <FaSearch className="w-6 h-8" />
          </Link>
          <Link
            className="block no-underline md:inline-block"
            key="cart"
            to="/cart"
          >
            {totalCartProducts > 0 && (
              <div className="absolute px-1 ml-5 -mt-2 text-xs font-bold text-white rounded shadow bg-primary">
                {totalCartProducts}
              </div>
            )}
            <AiOutlineShopping className="w-8 h-8" />
          </Link>
        </div>
      </div>
      <div className="md:hidden">
        <ul
          className="hidden md:flex md:flex-row"
          id="mobileMenu"
          style={{ padding: 0, display: isExpanded ? `block` : `none` }}
        >
          <div className="w-full md:w-3/5">
            <div className="shadow-md">
              {menu?.map(({ title, submenu }, index) => (
                <div
                  className="w-full overflow-hidden border-t tab"
                  key={index}
                >
                  <input
                    className="absolute opacity-0"
                    id={index}
                    name="tabs2"
                    type="radio"
                  ></input>
                  <label
                    className="block p-3 leading-normal cursor-pointer"
                    htmlFor={index}
                  >
                    {title}
                  </label>
                  <div className="overflow-hidden leading-normal tab-content bg-white-100">
                    {submenu.map(({ title, route }, index) => (
                      <div
                        className="py-2 overflow-hidden border-l-2 border-transparent border-double cursor-pointer select-none xl:my-2 xl:px-2 xl:w-1/2 bg-black-100 hover:border-black-500 category-size hover:border-current hover:text-black"
                        key={index}
                      >
                        <a
                          className="flex flex-wrap p-2 text-sm text-gray-700"
                          href={route}
                        >
                          {title}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ul>
      </div>
    </nav>
  );
};

NavBar.protoTypes = {
  menu: PropTypes.array.isRequired,
  onProfile: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCart: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  linkLogo: PropTypes.string
};

NavBar.defaultProps = {
  linkLogo: `#`
};
export default NavBar;
