import React from 'react';
import { graphql, Link } from 'gatsby';

function Footer({ data }) {
  const today = new Date().getFullYear();
  return (
    <footer className="bg-primary text-white">
      <nav className="flex justify-between px-4 lg:px-20 py-4 text-base">

        <div className="w-1/3 text-left">
          <ul>
            <li className="p-2">
              <Link className="underline" to="/entrega">Entrega</Link>
            </li>
            <li className="p-2">
              <Link className="underline" to="/trocas-devolucoes">Trocas e Devoluções</Link>
            </li>
            <li className="p-2">
              <Link className="underline" to="/como-comprar">Como Comprar</Link>
            </li>
          </ul>
        </div>
        <div className="w-1/3 text-center">
          <ul>
            <li className="p-2">
              <Link className="underline" to="/about">Sobre</Link>
            </li>
            <li className="p-2">
              <Link className="underline" to="/contact">Contato</Link>
            </li>
          </ul>
        </div>
        <div className="w-1/3 text-right">
          <ul>
            <li className="p-2">
              © {today} SóStrass
            </li>
            <li className="p-2">
              Powered by{` `}
              <a
                className="font-bold no-underline text-white"
                href="https://infranology.com.br"
                >
                Infranology
              </a>
            </li>
          </ul>
        </div>

      </nav>
    </footer>
  );
}

export default Footer;
