import React from 'react';
import { FaInstagram, FaFacebook, FaPinterest } from 'react-icons/fa';

export default ({ children }) => (
  <div className="flex">
    <a
      href="https://www.instagram.com/shop.sostrass/"
      rel="noopener noreferrer nofollow"
      target="_blank"
      className="p-2"
    >
      <FaInstagram className="h-8 w-8 m-2 text-primary" />
    </a>

    <a
      href="https://www.facebook.com/loja.sostrass/"
      rel="noopener noreferrer nofollow"
      target="_blank"
      className="p-2"
    >
      <FaFacebook className="h-8 w-8 m-2 text-primary" />
    </a>

    <a
      href="https://br.pinterest.com/sostrass/"
      rel="noopener noreferrer nofollow"
      target="_blank"
      className="p-2"
    >
      <FaPinterest className="h-8 w-8 m-2 text-primary" />
    </a>
  </div>
);
