import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Ofertasimperdiveis from './Promotion/OfertasImperdiveis';
import Parcelamentofretegratis from './Promotion/ParcelamentoFreteGratis';
import Footer from './Footer';
import Address from './Footer/Address';
import Selos from './Footer/Selos';
import Whatsapp from './Footer/Whatsapp';
import PaymentFlags from './Footer/PaymentFlags';
import StoreContext from '../context/StoreContext';

function Layout({ children }) {
  return (
    <>
      <StoreContext.Consumer>
        {context => {
          if (!context.state) {
            return <></>;
          }
          return (
            <div className="flex flex-col min-h-screen">
              <Parcelamentofretegratis />
              <Header
                addToCart={context.addProductToCart}
                totalCartProducts={context.state.totalCartProducts}
              />
              <main className="flex flex-auto md:justify-center max-w-full mx-auto w-full text-xl text-gray-600">
                {children}
              </main>
              <Footer />
              <PaymentFlags />
              <Selos />
              <Address />
              {/* <Whatsapp /> */}
            </div>
          );
        }}
      </StoreContext.Consumer>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
